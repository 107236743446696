import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import macro from '../../macros.js';
import vtkDataArray from '../../Common/Core/DataArray.js';
import vtkPolyData from '../../Common/DataModel/PolyData.js';
import vtkMatrixBuilder from '../../Common/Core/MatrixBuilder.js';

// vtkCubeSource methods
// ----------------------------------------------------------------------------

function vtkCubeSource(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkCubeSource');

  function requestData(inData, outData) {
    var _vtkMatrixBuilder$bui;

    if (model.deleted) {
      return;
    }

    var polyData = vtkPolyData.newInstance();
    outData[0] = polyData;
    var numberOfPolys = 6;
    var numberOfPoints = 24; // Define points

    var points = macro.newTypedArray(model.pointType, numberOfPoints * 3);
    polyData.getPoints().setData(points, 3);
    var normals = macro.newTypedArray(model.pointType, numberOfPoints * 3);
    var normalArray = vtkDataArray.newInstance({
      name: 'Normals',
      values: normals,
      numberOfComponents: 3
    });
    polyData.getPointData().setNormals(normalArray);
    var tcdim = 2;

    if (model.generate3DTextureCoordinates === true) {
      tcdim = 3;
    }

    var textureCoords = macro.newTypedArray(model.pointType, numberOfPoints * tcdim);
    var tcoords = vtkDataArray.newInstance({
      name: 'TextureCoordinates',
      values: textureCoords,
      numberOfComponents: tcdim
    });
    polyData.getPointData().setTCoords(tcoords);
    var x = [0.0, 0.0, 0.0];
    var n = [0.0, 0.0, 0.0];
    var tc = [0.0, 0.0];
    var pointIndex = 0;
    x[0] = -model.xLength / 2.0;
    n[0] = -1.0;
    n[1] = 0.0;
    n[2] = 0.0;

    for (var i = 0; i < 2; i++) {
      x[1] = -model.yLength / 2.0;

      for (var j = 0; j < 2; j++) {
        tc[1] = x[1] + 0.5;
        x[2] = -model.zLength / 2.0;

        for (var k = 0; k < 2; k++) {
          tc[0] = (x[2] + 0.5) * (1 - 2 * i);
          points[pointIndex * 3] = x[0];
          points[pointIndex * 3 + 1] = x[1];
          points[pointIndex * 3 + 2] = x[2];
          normals[pointIndex * 3] = n[0];
          normals[pointIndex * 3 + 1] = n[1];
          normals[pointIndex * 3 + 2] = n[2];

          if (tcdim === 2) {
            textureCoords[pointIndex * tcdim] = tc[0];
            textureCoords[pointIndex * tcdim + 1] = tc[1];
          } else {
            textureCoords[pointIndex * tcdim] = 2 * i - 1;
            textureCoords[pointIndex * tcdim + 1] = 2 * j - 1;
            textureCoords[pointIndex * tcdim + 2] = 2 * k - 1;
          }

          pointIndex++;
          x[2] += model.zLength;
        }

        x[1] += model.yLength;
      }

      x[0] += model.xLength;
      n[0] += 2.0;
    }

    x[1] = -model.yLength / 2.0;
    n[1] = -1.0;
    n[0] = 0.0;
    n[2] = 0.0;

    for (var _i = 0; _i < 2; _i++) {
      x[0] = -model.xLength / 2.0;

      for (var _j = 0; _j < 2; _j++) {
        tc[0] = (x[0] + 0.5) * (2 * _i - 1);
        x[2] = -model.zLength / 2.0;

        for (var _k = 0; _k < 2; _k++) {
          tc[1] = (x[2] + 0.5) * -1;
          points[pointIndex * 3] = x[0];
          points[pointIndex * 3 + 1] = x[1];
          points[pointIndex * 3 + 2] = x[2];
          normals[pointIndex * 3] = n[0];
          normals[pointIndex * 3 + 1] = n[1];
          normals[pointIndex * 3 + 2] = n[2];

          if (tcdim === 2) {
            textureCoords[pointIndex * tcdim] = tc[0];
            textureCoords[pointIndex * tcdim + 1] = tc[1];
          } else {
            textureCoords[pointIndex * tcdim] = 2 * _j - 1;
            textureCoords[pointIndex * tcdim + 1] = 2 * _i - 1;
            textureCoords[pointIndex * tcdim + 2] = 2 * _k - 1;
          }

          pointIndex++;
          x[2] += model.zLength;
        }

        x[0] += model.xLength;
      }

      x[1] += model.yLength;
      n[1] += 2.0;
    }

    x[2] = -model.zLength / 2.0;
    n[2] = -1.0;
    n[0] = 0.0;
    n[1] = 0.0;

    for (var _i2 = 0; _i2 < 2; _i2++) {
      x[1] = -model.yLength / 2.0;

      for (var _j2 = 0; _j2 < 2; _j2++) {
        tc[1] = x[1] + 0.5;
        x[0] = -model.xLength / 2.0;

        for (var _k2 = 0; _k2 < 2; _k2++) {
          tc[0] = (x[0] + 0.5) * (2 * _i2 - 1);
          points[pointIndex * 3] = x[0];
          points[pointIndex * 3 + 1] = x[1];
          points[pointIndex * 3 + 2] = x[2];
          normals[pointIndex * 3] = n[0];
          normals[pointIndex * 3 + 1] = n[1];
          normals[pointIndex * 3 + 2] = n[2];

          if (tcdim === 2) {
            textureCoords[pointIndex * tcdim] = tc[0];
            textureCoords[pointIndex * tcdim + 1] = tc[1];
          } else {
            textureCoords[pointIndex * tcdim] = 2 * _k2 - 1;
            textureCoords[pointIndex * tcdim + 1] = 2 * _j2 - 1;
            textureCoords[pointIndex * tcdim + 2] = 2 * _i2 - 1;
          }

          pointIndex++;
          x[0] += model.xLength;
        }

        x[1] += model.yLength;
      }

      x[2] += model.zLength;
      n[2] += 2.0;
    } // Apply rotation to the points coordinates and normals


    vtkMatrixBuilder.buildFromDegree().rotateX(model.rotations[0]).rotateY(model.rotations[1]).rotateZ(model.rotations[2]).apply(points).apply(normals); // Apply transformation to the points coordinates

    (_vtkMatrixBuilder$bui = vtkMatrixBuilder.buildFromRadian()).translate.apply(_vtkMatrixBuilder$bui, _toConsumableArray(model.center)).apply(points); // Define quads


    var polys = new Uint16Array(numberOfPolys * 5);
    polyData.getPolys().setData(polys, 1);
    var polyIndex = 0;
    polys[polyIndex++] = 4;
    polys[polyIndex++] = 0;
    polys[polyIndex++] = 1;
    polys[polyIndex++] = 3;
    polys[polyIndex++] = 2;
    polys[polyIndex++] = 4;
    polys[polyIndex++] = 4;
    polys[polyIndex++] = 6;
    polys[polyIndex++] = 7;
    polys[polyIndex++] = 5;
    polys[polyIndex++] = 4;
    polys[polyIndex++] = 8;
    polys[polyIndex++] = 10;
    polys[polyIndex++] = 11;
    polys[polyIndex++] = 9;
    polys[polyIndex++] = 4;
    polys[polyIndex++] = 12;
    polys[polyIndex++] = 13;
    polys[polyIndex++] = 15;
    polys[polyIndex++] = 14;
    polys[polyIndex++] = 4;
    polys[polyIndex++] = 16;
    polys[polyIndex++] = 18;
    polys[polyIndex++] = 19;
    polys[polyIndex++] = 17;
    polys[polyIndex++] = 4;
    polys[polyIndex++] = 20;
    polys[polyIndex++] = 21;
    polys[polyIndex++] = 23;
    polys[polyIndex] = 22;
  }

  publicAPI.setBounds = function () {
    var boundsArray = [];

    if (Array.isArray(arguments.length <= 0 ? undefined : arguments[0])) {
      boundsArray = arguments.length <= 0 ? undefined : arguments[0];
    } else {
      for (var i = 0; i < arguments.length; i++) {
        boundsArray.push(i < 0 || arguments.length <= i ? undefined : arguments[i]);
      }
    }

    if (boundsArray.length !== 6) {
      return;
    }

    model.xLength = boundsArray[1] - boundsArray[0];
    model.yLength = boundsArray[3] - boundsArray[2];
    model.zLength = boundsArray[5] - boundsArray[4];
    model.center = [(boundsArray[0] + boundsArray[1]) / 2.0, (boundsArray[2] + boundsArray[3]) / 2.0, (boundsArray[4] + boundsArray[5]) / 2.0];
  }; // Expose methods


  publicAPI.requestData = requestData;
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  xLength: 1.0,
  yLength: 1.0,
  zLength: 1.0,
  center: [0.0, 0.0, 0.0],
  rotations: [0.0, 0.0, 0.0],
  pointType: 'Float64Array',
  generate3DTextureCoordinates: false
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Build VTK API

  macro.obj(publicAPI, model);
  macro.setGet(publicAPI, model, ['xLength', 'yLength', 'zLength', 'generate3DTextureCoordinates']);
  macro.setGetArray(publicAPI, model, ['center', 'rotations'], 3);
  macro.algo(publicAPI, model, 0, 1);
  vtkCubeSource(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkCubeSource'); // ----------------------------------------------------------------------------

var vtkCubeSource$1 = {
  newInstance: newInstance,
  extend: extend
};

export { vtkCubeSource$1 as default, extend, newInstance };
