import _defineProperty from '@babel/runtime/helpers/defineProperty';
import _toConsumableArray from '@babel/runtime/helpers/toConsumableArray';
import macro from '../../macros.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
// vtkTexture methods
// ----------------------------------------------------------------------------

function vtkTexture(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkTexture');

  publicAPI.imageLoaded = function () {
    model.image.removeEventListener('load', publicAPI.imageLoaded);
    model.imageLoaded = true;
    publicAPI.modified();
  };

  publicAPI.setJsImageData = function (imageData) {
    if (model.jsImageData === imageData) {
      return;
    } // clear other entries


    if (imageData !== null) {
      publicAPI.setInputData(null);
      publicAPI.setInputConnection(null);
      model.image = null;
      model.canvas = null;
    }

    model.jsImageData = imageData;
    model.imageLoaded = true;
    publicAPI.modified();
  };

  publicAPI.setCanvas = function (canvas) {
    if (model.canvas === canvas) {
      return;
    } // clear other entries


    if (canvas !== null) {
      publicAPI.setInputData(null);
      publicAPI.setInputConnection(null);
      model.image = null;
      model.jsImageData = null;
    }

    model.canvas = canvas;
    publicAPI.modified();
  };

  publicAPI.setImage = function (image) {
    if (model.image === image) {
      return;
    } // clear other entries


    if (image !== null) {
      publicAPI.setInputData(null);
      publicAPI.setInputConnection(null);
      model.canvas = null;
      model.jsImageData = null;
    }

    model.image = image;
    model.imageLoaded = false;

    if (image.complete) {
      publicAPI.imageLoaded();
    } else {
      image.addEventListener('load', publicAPI.imageLoaded);
    }

    publicAPI.modified();
  };

  publicAPI.getDimensionality = function () {
    var width = 0;
    var height = 0;
    var depth = 1;

    if (publicAPI.getInputData()) {
      var data = publicAPI.getInputData();
      width = data.getDimensions()[0];
      height = data.getDimensions()[1];
      depth = data.getDimensions()[2];
    }

    if (model.jsImageData) {
      width = model.jsImageData.width;
      height = model.jsImageData.height;
    }

    if (model.canvas) {
      width = model.canvas.width;
      height = model.canvas.height;
    }

    if (model.image) {
      width = model.image.width;
      height = model.image.height;
    }

    var dimensionality = (width > 1) + (height > 1) + (depth > 1);
    return dimensionality;
  };

  publicAPI.getInputAsJsImageData = function () {
    if (!model.imageLoaded || publicAPI.getInputData()) return null;

    if (model.jsImageData) {
      return model.jsImageData();
    }

    if (model.canvas) {
      var context = model.canvas.getContext('2d');
      var imageData = context.getImageData(0, 0, model.canvas.width, model.canvas.height);
      return imageData;
    }

    if (model.image) {
      var canvas = document.createElement('canvas');
      canvas.width = model.image.width;
      canvas.height = model.image.height;

      var _context = canvas.getContext('2d');

      _context.translate(0, canvas.height);

      _context.scale(1, -1);

      _context.drawImage(model.image, 0, 0, model.image.width, model.image.height);

      var _imageData = _context.getImageData(0, 0, canvas.width, canvas.height);

      return _imageData;
    }

    return null;
  };
} // Use nativeArray instead of self


var generateMipmaps = function generateMipmaps(nativeArray, width, height, level) {
  // TODO: FIX UNEVEN TEXTURE MIP GENERATION:
  // When textures don't have standard ratios, higher mip levels
  // result in their color chanels getting messed up and shifting
  // 3x3 gaussian kernel
  var g3m = [1, 2, 1]; // eslint-disable-line

  var g3w = 4; // eslint-disable-line

  var kernel = g3m;
  var kernelWeight = g3w;
  var hs = nativeArray.length / (width * height); // TODO: support for textures with depth more than 1

  var currentWidth = width;
  var currentHeight = height;
  var imageData = nativeArray;
  var maps = [imageData];

  for (var i = 0; i < level; i++) {
    var oldData = _toConsumableArray(imageData);

    currentWidth /= 2;
    currentHeight /= 2;
    imageData = new Uint8ClampedArray(currentWidth * currentHeight * hs);
    var vs = hs * currentWidth; // Scale down

    var shift = 0;

    for (var p = 0; p < imageData.length; p += hs) {
      if (p % vs === 0) {
        shift += 2 * hs * currentWidth;
      }

      for (var c = 0; c < hs; c++) {
        var sample = oldData[shift + c];
        sample += oldData[shift + hs + c];
        sample += oldData[shift - 2 * vs + c];
        sample += oldData[shift - 2 * vs + hs + c];
        sample /= 4;
        imageData[p + c] = sample;
      }

      shift += 2 * hs;
    } // Horizontal Pass


    var dataCopy = _toConsumableArray(imageData);

    for (var _p = 0; _p < imageData.length; _p += hs) {
      for (var _c = 0; _c < hs; _c++) {
        var x = -(kernel.length - 1) / 2;
        var kw = kernelWeight;
        var value = 0.0;

        for (var k = 0; k < kernel.length; k++) {
          var index = _p + _c + x * hs;
          var lineShift = index % vs - (_p + _c) % vs;
          if (lineShift > hs) index += vs;
          if (lineShift < -hs) index -= vs;

          if (dataCopy[index]) {
            value += dataCopy[index] * kernel[k];
          } else {
            kw -= kernel[k];
          }

          x += 1;
        }

        imageData[_p + _c] = value / kw;
      }
    } // Vertical Pass


    dataCopy = _toConsumableArray(imageData);

    for (var _p2 = 0; _p2 < imageData.length; _p2 += hs) {
      for (var _c2 = 0; _c2 < hs; _c2++) {
        var _x = -(kernel.length - 1) / 2;

        var _kw = kernelWeight;
        var _value = 0.0;

        for (var _k = 0; _k < kernel.length; _k++) {
          var _index = _p2 + _c2 + _x * vs;

          if (dataCopy[_index]) {
            _value += dataCopy[_index] * kernel[_k];
          } else {
            _kw -= kernel[_k];
          }

          _x += 1;
        }

        imageData[_p2 + _c2] = _value / _kw;
      }
    }

    maps.push(imageData);
  }

  return maps;
}; // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  image: null,
  canvas: null,
  jsImageData: null,
  imageLoaded: false,
  repeat: false,
  interpolate: false,
  edgeClamp: false,
  mipLevel: 0,
  resizable: false // must be set at construction time if the texture can be resizable

}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Build VTK API

  macro.obj(publicAPI, model);
  macro.algo(publicAPI, model, 6, 0);
  macro.get(publicAPI, model, ['canvas', 'image', 'jsImageData', 'imageLoaded', 'resizable']);
  macro.setGet(publicAPI, model, ['repeat', 'edgeClamp', 'interpolate', 'mipLevel']);
  vtkTexture(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkTexture');
var STATIC = {
  generateMipmaps: generateMipmaps
}; // ----------------------------------------------------------------------------

var vtkTexture$1 = _objectSpread({
  newInstance: newInstance,
  extend: extend
}, STATIC);

export { STATIC, vtkTexture$1 as default, extend, newInstance };
