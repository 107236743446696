import _defineProperty from '@babel/runtime/helpers/defineProperty';
import macro from '../../macros.js';
import { N as createUninitializedBounds, C as clampValue, J as floor } from '../../Common/Core/Math/index.js';
import Constants from './VolumeMapper/Constants.js';
import vtkAbstractMapper from './AbstractMapper.js';
import vtkPiecewiseFunction from '../../Common/DataModel/PiecewiseFunction.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var BlendMode = Constants.BlendMode,
    FilterMode = Constants.FilterMode;

function createRadonTransferFunction(firstAbsorbentMaterialHounsfieldValue, firstAbsorbentMaterialAbsorption, maxAbsorbentMaterialHounsfieldValue, maxAbsorbentMaterialAbsorption, outputTransferFunction) {
  var ofun = null;

  if (outputTransferFunction) {
    ofun = outputTransferFunction;
    ofun.removeAllPoints();
  } else {
    ofun = vtkPiecewiseFunction.newInstance();
  }

  ofun.addPointLong(-1024, 0, 1, 1); // air (i.e. material with no absorption)

  ofun.addPoint(firstAbsorbentMaterialHounsfieldValue, firstAbsorbentMaterialAbsorption);
  ofun.addPoint(maxAbsorbentMaterialHounsfieldValue, maxAbsorbentMaterialAbsorption);
  return ofun;
} // ----------------------------------------------------------------------------
// Static API
// ----------------------------------------------------------------------------


var STATIC = {
  createRadonTransferFunction: createRadonTransferFunction
}; // ----------------------------------------------------------------------------
// vtkVolumeMapper methods
// ----------------------------------------------------------------------------

function vtkVolumeMapper(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkVolumeMapper');

  var superClass = _objectSpread({}, publicAPI);

  publicAPI.getBounds = function () {
    var input = publicAPI.getInputData();

    if (!input) {
      model.bounds = createUninitializedBounds();
    } else {
      if (!model.static) {
        publicAPI.update();
      }

      model.bounds = input.getBounds();
    }

    return model.bounds;
  };

  publicAPI.update = function () {
    publicAPI.getInputData();
  };

  publicAPI.setBlendModeToComposite = function () {
    publicAPI.setBlendMode(BlendMode.COMPOSITE_BLEND);
  };

  publicAPI.setBlendModeToMaximumIntensity = function () {
    publicAPI.setBlendMode(BlendMode.MAXIMUM_INTENSITY_BLEND);
  };

  publicAPI.setBlendModeToMinimumIntensity = function () {
    publicAPI.setBlendMode(BlendMode.MINIMUM_INTENSITY_BLEND);
  };

  publicAPI.setBlendModeToAverageIntensity = function () {
    publicAPI.setBlendMode(BlendMode.AVERAGE_INTENSITY_BLEND);
  };

  publicAPI.setBlendModeToAdditiveIntensity = function () {
    publicAPI.setBlendMode(BlendMode.ADDITIVE_INTENSITY_BLEND);
  };

  publicAPI.setBlendModeToRadonTransform = function () {
    publicAPI.setBlendMode(BlendMode.RADON_TRANSFORM_BLEND);
  };

  publicAPI.getBlendModeAsString = function () {
    return macro.enumToString(BlendMode, model.blendMode);
  };

  publicAPI.setAverageIPScalarRange = function (min, max) {
    console.warn('setAverageIPScalarRange is deprecated use setIpScalarRange');
    publicAPI.setIpScalarRange(min, max);
  };

  publicAPI.getFilterModeAsString = function () {
    return macro.enumToString(FilterMode, model.filterMode);
  };

  publicAPI.setFilterModeToOff = function () {
    publicAPI.setFilterMode(FilterMode.OFF);
  };

  publicAPI.setFilterModeToNormalized = function () {
    publicAPI.setFilterMode(FilterMode.NORMALIZED);
  };

  publicAPI.setFilterModeToRaw = function () {
    publicAPI.setFilterMode(FilterMode.RAW);
  };

  publicAPI.setGlobalIlluminationReach = function (gl) {
    return superClass.setGlobalIlluminationReach(clampValue(gl, 0.0, 1.0));
  };

  publicAPI.setVolumetricScatteringBlending = function (vsb) {
    return superClass.setVolumetricScatteringBlending(clampValue(vsb, 0.0, 1.0));
  };

  publicAPI.setVolumeShadowSamplingDistFactor = function (vsdf) {
    return superClass.setVolumeShadowSamplingDistFactor(vsdf >= 1.0 ? vsdf : 1.0);
  };

  publicAPI.setAnisotropy = function (at) {
    return superClass.setAnisotropy(clampValue(at, -0.99, 0.99));
  };

  publicAPI.setLAOKernelSize = function (ks) {
    return superClass.setLAOKernelSize(floor(clampValue(ks, 1, 32)));
  };

  publicAPI.setLAOKernelRadius = function (kr) {
    return superClass.setLAOKernelRadius(kr >= 1 ? kr : 1);
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------
// TODO: what values to use for averageIPScalarRange to get GLSL to use max / min values like [-Math.inf, Math.inf]?


var DEFAULT_VALUES = {
  bounds: [1, -1, 1, -1, 1, -1],
  sampleDistance: 1.0,
  imageSampleDistance: 1.0,
  maximumSamplesPerRay: 1000,
  autoAdjustSampleDistances: true,
  blendMode: BlendMode.COMPOSITE_BLEND,
  ipScalarRange: [-1000000.0, 1000000.0],
  filterMode: FilterMode.OFF,
  // ignored by WebGL so no behavior change
  preferSizeOverAccuracy: false,
  // Whether to use halfFloat representation of float, when it is inaccurate
  computeNormalFromOpacity: false,
  // volume shadow parameters
  volumetricScatteringBlending: 0.0,
  globalIlluminationReach: 0.0,
  volumeShadowSamplingDistFactor: 5.0,
  anisotropy: 0.0,
  // local ambient occlusion
  localAmbientOcclusion: false,
  LAOKernelSize: 15,
  LAOKernelRadius: 7
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues);
  vtkAbstractMapper.extend(publicAPI, model, initialValues);
  macro.setGet(publicAPI, model, ['sampleDistance', 'imageSampleDistance', 'maximumSamplesPerRay', 'autoAdjustSampleDistances', 'blendMode', 'filterMode', 'preferSizeOverAccuracy', 'computeNormalFromOpacity', 'volumetricScatteringBlending', 'globalIlluminationReach', 'volumeShadowSamplingDistFactor', 'anisotropy', 'localAmbientOcclusion', 'LAOKernelSize', 'LAOKernelRadius']);
  macro.setGetArray(publicAPI, model, ['ipScalarRange'], 2);
  macro.event(publicAPI, model, 'lightingActivated'); // Object methods

  vtkVolumeMapper(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkVolumeMapper'); // ----------------------------------------------------------------------------

var vtkVolumeMapper$1 = _objectSpread({
  newInstance: newInstance,
  extend: extend
}, STATIC);

export { STATIC, vtkVolumeMapper$1 as default, extend, newInstance };
