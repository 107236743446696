import { newInstance as newInstance$1 } from '../../macros.js';
import vtkCubeAxesActor from '../Core/CubeAxesActor.js';
import vtkViewNode from '../SceneGraph/ViewNode.js';
import { registerOverride } from './ViewNodeFactory.js';

// vtkWebGPUCubeAxesActor methods
// ----------------------------------------------------------------------------

function vtkWebGPUCubeAxesActor(publicAPI, model) {
  model.classHierarchy.push('vtkWebGPUCubeAxesActor'); // Builds myself.

  publicAPI.buildPass = function (prepass) {
    if (prepass) {
      model.WebGPURenderer = publicAPI.getFirstAncestorOfType('vtkWebGPURenderer');
      model.WebGPURenderWindow = model.WebGPURenderer.getParent();

      if (!model.CubeAxesActorHelper.getRenderable()) {
        model.CubeAxesActorHelper.setRenderable(model.renderable);
      }

      publicAPI.prepareNodes();
      publicAPI.addMissingNode(model.CubeAxesActorHelper.getTmActor());
      publicAPI.addMissingNode(model.renderable.getGridActor());
      publicAPI.removeUnusedNodes();
    }
  };

  publicAPI.opaquePass = function (prepass, renderPass) {
    if (prepass) {
      var camera = model.WebGPURenderer ? model.WebGPURenderer.getRenderable().getActiveCamera() : null;
      var tsize = model.WebGPURenderer.getTiledSizeAndOrigin();
      model.CubeAxesActorHelper.updateAPISpecificData([tsize.usize, tsize.vsize], camera, model.WebGPURenderWindow.getRenderable());
    }
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Inheritance

  vtkViewNode.extend(publicAPI, model, initialValues);
  model.CubeAxesActorHelper = vtkCubeAxesActor.newCubeAxesActorHelper(); // Object methods

  vtkWebGPUCubeAxesActor(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = newInstance$1(extend, 'vtkWebGPUCubeAxesActor'); // ----------------------------------------------------------------------------

var index = {
  newInstance: newInstance,
  extend: extend
}; // Register ourself to WebGPU backend if imported

registerOverride('vtkCubeAxesActor', newInstance);

export { index as default, extend, newInstance };
