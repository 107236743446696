var XrSessionTypes = {
  HmdVR: 0,
  // Head-mounted display (HMD), two-camera virtual reality session
  MobileAR: 1,
  // Mobile device, single-camera augmented reality session
  LookingGlassVR: 2 // Looking Glass hologram display, N-camera virtual reality session

};
var Constants = {
  XrSessionTypes: XrSessionTypes
};

export { XrSessionTypes, Constants as default };
