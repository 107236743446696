import { mat4, vec3 } from 'gl-matrix';
import { newInstance as newInstance$1, obj, get, getArray, setGet, vtkDebugMacro as vtkDebugMacro$1 } from '../../macros.js';
import { r as radiansFromDegrees } from '../../Common/Core/Math/index.js';
import vtkViewNode from '../SceneGraph/ViewNode.js';
import vtkWebGPUBindGroup from './BindGroup.js';
import vtkWebGPUFullScreenQuad from './FullScreenQuad.js';
import vtkWebGPUStorageBuffer from './StorageBuffer.js';
import vtkWebGPUUniformBuffer from './UniformBuffer.js';
import { registerOverride } from './ViewNodeFactory.js';

var vtkDebugMacro = vtkDebugMacro$1;
var clearFragColorTemplate = "\n//VTK::Renderer::Dec\n\n//VTK::Mapper::Dec\n\n//VTK::TCoord::Dec\n\n//VTK::RenderEncoder::Dec\n\n//VTK::IOStructs::Dec\n\n@fragment\nfn main(\n//VTK::IOStructs::Input\n)\n//VTK::IOStructs::Output\n{\n  var output: fragmentOutput;\n\n  var computedColor: vec4<f32> = mapperUBO.BackgroundColor;\n\n  //VTK::RenderEncoder::Impl\n  return output;\n}\n";
var clearFragTextureTemplate = "\nfn vecToRectCoord(dir: vec3<f32>) -> vec2<f32> {\n  var tau: f32 = 6.28318530718;\n  var pi: f32 = 3.14159265359;\n  var out: vec2<f32> = vec2<f32>(0.0);\n\n  out.x = atan2(dir.z, dir.x) / tau;\n  out.x += 0.5;\n\n  var phix: f32 = length(vec2(dir.x, dir.z));\n  out.y = atan2(dir.y, phix) / pi + 0.5;\n\n  return out;\n}\n\n//VTK::Renderer::Dec\n\n//VTK::Mapper::Dec\n\n//VTK::TCoord::Dec\n\n//VTK::RenderEncoder::Dec\n\n//VTK::IOStructs::Dec\n\n@fragment\nfn main(\n//VTK::IOStructs::Input\n)\n//VTK::IOStructs::Output\n{\n  var output: fragmentOutput;\n\n  var tcoord: vec4<f32> = vec4<f32>(input.vertexVC.xy, -1, 1);\n  var V: vec4<f32> = normalize(mapperUBO.FSQMatrix * tcoord); // vec2<f32>((input.tcoordVS.x - 0.5) * 2, -(input.tcoordVS.y - 0.5) * 2);\n  // textureSampleLevel gets rid of some ugly artifacts\n  var background = textureSampleLevel(EnvironmentTexture, EnvironmentTextureSampler, vecToRectCoord(V.xyz), 0);\n  var computedColor: vec4<f32> = vec4<f32>(background.rgb, 1);\n\n  //VTK::RenderEncoder::Impl\n  return output;\n}\n";

var _fsqClearMat4 = new Float64Array(16);

var _tNormalMat4 = new Float64Array(16); // Light type index gives either 0, 1, or 2 which indicates what type of light there is.
// While technically, there are only spot and directional lights, within the CellArrayMapper
// there is a third, positional light. It is technically just a variant of a spot light with
// a cone angle of 90 or above, however certain calculations can be skipped if it is treated
// separately.
// The mappings are shown below:
// 0 -> positional light
// 1 -> directional light
// 2 -> spot light


function getLightTypeIndex(light) {
  if (light.getPositional()) {
    if (light.getConeAngle() >= 90) {
      return 0;
    }

    return 2;
  }

  return 1;
} // ----------------------------------------------------------------------------
// vtkWebGPURenderer methods
// ----------------------------------------------------------------------------

/* eslint-disable no-bitwise */


function vtkWebGPURenderer(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkWebGPURenderer'); // Builds myself.

  publicAPI.buildPass = function (prepass) {
    if (prepass) {
      if (!model.renderable) {
        return;
      }

      model.camera = model.renderable.getActiveCamera();
      publicAPI.updateLights();
      publicAPI.prepareNodes();
      publicAPI.addMissingNode(model.camera);
      publicAPI.addMissingNodes(model.renderable.getViewPropsWithNestedProps());
      publicAPI.removeUnusedNodes();
      model.webgpuCamera = publicAPI.getViewNodeFor(model.camera);
      publicAPI.updateStabilizedMatrix();
    }
  };

  publicAPI.updateStabilizedMatrix = function () {
    // This method is designed to help with floating point
    // issues when rendering datasets that push the limits of
    // resolutions on float.
    //
    // One of the most common cases is when the dataset is located far
    // away from the origin relative to the clipping range we are looking
    // at. For that case we want to perform the floating point sensitive
    // multiplications on the CPU in double. To this end we want the
    // vertex rendering ops to look something like
    //
    // Compute shifted points and load those into the VBO
    // pointCoordsSC = WorldToStabilizedMatrix * pointCoords;
    //
    // In the vertex shader do the following
    // positionVC = StabilizedToDeviceMatrix * ModelToStabilizedMatrix*vertexIn;
    //
    // We use two matrices because it is expensive to change the
    // WorldToStabilized matrix as we have to reupload all pointCoords
    // So that matrix (MCSCMatrix) is fairly static, the Stabilized to
    // Device matrix is the one that gets updated every time the camera
    // changes.
    //
    // The basic idea is that we should translate the data so that
    // when the center of the view frustum moves a lot
    // we recenter it. The center of the view frustum is roughly
    // camPos + dirOfProj*(far + near)*0.5
    var clipRange = model.camera.getClippingRange();
    var pos = model.camera.getPositionByReference();
    var dop = model.camera.getDirectionOfProjectionByReference();
    var center = [];
    var offset = [];
    vec3.scale(offset, dop, 0.5 * (clipRange[0] + clipRange[1]));
    vec3.add(center, pos, offset);
    vec3.sub(offset, center, model.stabilizedCenter);
    var length = vec3.len(offset);

    if (length / (clipRange[1] - clipRange[0]) > model.recenterThreshold) {
      model.stabilizedCenter = center;
      model.stabilizedTime.modified();
    }
  };

  publicAPI.updateLights = function () {
    var count = 0;
    var lights = model.renderable.getLightsByReference();

    for (var index = 0; index < lights.length; ++index) {
      if (lights[index].getSwitch() > 0.0) {
        count++;
      }
    }

    if (!count) {
      vtkDebugMacro('No lights are on, creating one.');
      model.renderable.createLight();
    }

    return count;
  };

  publicAPI.updateUBO = function () {
    // make sure the data is up to date
    // has the camera changed?
    var utime = model.UBO.getSendTime();

    if (model._parent.getMTime() > utime || publicAPI.getMTime() > utime || model.camera.getMTime() > utime || model.renderable.getMTime() > utime) {
      var _model$renderable$get;

      var keyMats = model.webgpuCamera.getKeyMatrices(publicAPI);
      model.UBO.setArray('WCVCMatrix', keyMats.wcvc);
      model.UBO.setArray('SCPCMatrix', keyMats.scpc);
      model.UBO.setArray('PCSCMatrix', keyMats.pcsc);
      model.UBO.setArray('SCVCMatrix', keyMats.scvc);
      model.UBO.setArray('VCPCMatrix', keyMats.vcpc);
      model.UBO.setArray('WCVCNormals', keyMats.normalMatrix);
      model.UBO.setValue('LightCount', model.renderable.getLights().length);
      model.UBO.setValue('MaxEnvironmentMipLevel', (_model$renderable$get = model.renderable.getEnvironmentTexture()) === null || _model$renderable$get === void 0 ? void 0 : _model$renderable$get.getMipLevel());
      model.UBO.setValue('BackgroundDiffuseStrength', model.renderable.getEnvironmentTextureDiffuseStrength());
      model.UBO.setValue('BackgroundSpecularStrength', model.renderable.getEnvironmentTextureSpecularStrength());
      var tsize = publicAPI.getYInvertedTiledSizeAndOrigin();
      model.UBO.setArray('viewportSize', [tsize.usize, tsize.vsize]);
      model.UBO.setValue('cameraParallel', model.camera.getParallelProjection());

      var device = model._parent.getDevice();

      model.UBO.sendIfNeeded(device);
    }
  };

  publicAPI.updateSSBO = function () {
    var lights = model.renderable.getLights();
    var keyMats = model.webgpuCamera.getKeyMatrices(publicAPI);
    var lightTimeString = "".concat(model.renderable.getMTime());

    for (var i = 0; i < lights.length; i++) {
      lightTimeString += lights[i].getMTime();
    }

    if (lightTimeString !== model.lightTimeString) {
      var lightPosArray = new Float32Array(lights.length * 4);
      var lightDirArray = new Float32Array(lights.length * 4);
      var lightColorArray = new Float32Array(lights.length * 4);
      var lightTypeArray = new Float32Array(lights.length * 4);

      for (var _i = 0; _i < lights.length; _i++) {
        var offset = _i * 4; // Position

        var viewCoordinatePosition = lights[_i].getPosition();

        vec3.transformMat4(viewCoordinatePosition, viewCoordinatePosition, keyMats.wcvc); // viewCoordinatePosition

        lightPosArray[offset] = viewCoordinatePosition[0];
        lightPosArray[offset + 1] = viewCoordinatePosition[1];
        lightPosArray[offset + 2] = viewCoordinatePosition[2];
        lightPosArray[offset + 3] = 0; // Rotation (All are negative to correct for -Z being forward)

        lightDirArray[offset] = -lights[_i].getDirection()[0];
        lightDirArray[offset + 1] = -lights[_i].getDirection()[1];
        lightDirArray[offset + 2] = -lights[_i].getDirection()[2];
        lightDirArray[offset + 3] = 0; // Color

        lightColorArray[offset] = lights[_i].getColor()[0];
        lightColorArray[offset + 1] = lights[_i].getColor()[1];
        lightColorArray[offset + 2] = lights[_i].getColor()[2];
        lightColorArray[offset + 3] = lights[_i].getIntensity() * 5; // arbitrary multiplication to fix the dullness of low value PBR lights
        // Type

        lightTypeArray[offset] = getLightTypeIndex(lights[_i]); // Type

        lightTypeArray[offset + 1] = Math.cos(radiansFromDegrees(lights[_i].getConeAngle())); // Inner Phi, should probably do some check on these to make sure they dont excede limits

        lightTypeArray[offset + 2] = Math.cos(radiansFromDegrees(lights[_i].getConeAngle() + lights[_i].getConeFalloff())); // Outer Phi

        lightTypeArray[offset + 3] = 0;
      } // Im not sure how correct this is, but this is what the example does
      // https://kitware.github.io/vtk-js/api/Rendering_WebGPU_VolumePassFSQ.html


      model.SSBO.clearData();
      model.SSBO.setNumberOfInstances(lights.length);
      model.SSBO.addEntry('LightPos', 'vec4<f32>'); // Position

      model.SSBO.addEntry('LightDir', 'vec4<f32>'); // Direction

      model.SSBO.addEntry('LightColor', 'vec4<f32>'); // Color (r, g, b, intensity)

      model.SSBO.addEntry('LightData', 'vec4<f32>'); // Other data (type, etc, etc, etc)

      model.SSBO.setAllInstancesFromArray('LightPos', lightPosArray);
      model.SSBO.setAllInstancesFromArray('LightDir', lightDirArray);
      model.SSBO.setAllInstancesFromArray('LightColor', lightColorArray);
      model.SSBO.setAllInstancesFromArray('LightData', lightTypeArray);

      var device = model._parent.getDevice();

      model.SSBO.send(device);
    }

    model.lightTimeString = lightTimeString;
  };

  publicAPI.scissorAndViewport = function (encoder) {
    var tsize = publicAPI.getYInvertedTiledSizeAndOrigin();
    encoder.getHandle().setViewport(tsize.lowerLeftU, tsize.lowerLeftV, tsize.usize, tsize.vsize, 0.0, 1.0); // set scissor

    encoder.getHandle().setScissorRect(tsize.lowerLeftU, tsize.lowerLeftV, tsize.usize, tsize.vsize);
  };

  publicAPI.bindUBO = function (renderEncoder) {
    renderEncoder.activateBindGroup(model.bindGroup);
  }; // Renders myself


  publicAPI.opaquePass = function (prepass) {
    if (prepass) {
      model.renderEncoder.begin(model._parent.getCommandEncoder());
      publicAPI.updateUBO();
      publicAPI.updateSSBO();
    } else {
      publicAPI.scissorAndViewport(model.renderEncoder);
      publicAPI.clear();
      model.renderEncoder.end();
    }
  };

  publicAPI.clear = function () {
    var _model$backgroundTex;

    if (model.renderable.getTransparent() || model.suppressClear) {
      return;
    }

    var device = model._parent.getDevice(); // Normal Solid Color


    if (!model.clearFSQ) {
      model.clearFSQ = vtkWebGPUFullScreenQuad.newInstance();
      model.clearFSQ.setDevice(device);
      model.clearFSQ.setPipelineHash('clearfsq');
      model.clearFSQ.setFragmentShaderTemplate(clearFragColorTemplate);
      var ubo = vtkWebGPUUniformBuffer.newInstance({
        label: 'mapperUBO'
      });
      ubo.addEntry('FSQMatrix', 'mat4x4<f32>');
      ubo.addEntry('BackgroundColor', 'vec4<f32>');
      model.clearFSQ.setUBO(ubo);
      model.backgroundTex = model.renderable.getEnvironmentTexture();
    } // Textured Background


    if (model.clearFSQ.getPipelineHash() !== 'clearfsqwithtexture' && model.renderable.getUseEnvironmentTextureAsBackground() && (_model$backgroundTex = model.backgroundTex) !== null && _model$backgroundTex !== void 0 && _model$backgroundTex.getImageLoaded()) {
      model.clearFSQ.setFragmentShaderTemplate(clearFragTextureTemplate);

      var _ubo = vtkWebGPUUniformBuffer.newInstance({
        label: 'mapperUBO'
      });

      _ubo.addEntry('FSQMatrix', 'mat4x4<f32>');

      _ubo.addEntry('BackgroundColor', 'vec4<f32>');

      model.clearFSQ.setUBO(_ubo);
      var environmentTextureHash = device.getTextureManager().getTextureForVTKTexture(model.backgroundTex);

      if (environmentTextureHash.getReady()) {
        var tview = environmentTextureHash.createView("EnvironmentTexture");
        model.clearFSQ.setTextureViews([tview]);
        model.backgroundTexLoaded = true;
        var interpolate = model.backgroundTex.getInterpolate() ? 'linear' : 'nearest';
        tview.addSampler(device, {
          addressModeU: 'repeat',
          addressModeV: 'clamp-to-edge',
          addressModeW: 'repeat',
          minFilter: interpolate,
          magFilter: interpolate,
          mipmapFilter: 'linear'
        });
      }

      model.clearFSQ.setPipelineHash('clearfsqwithtexture');
    } else if (model.clearFSQ.getPipelineHash() === 'clearfsqwithtexture' && !model.renderable.getUseEnvironmentTextureAsBackground()) {
      // In case the mode is changed at runtime
      model.clearFSQ = vtkWebGPUFullScreenQuad.newInstance();
      model.clearFSQ.setDevice(device);
      model.clearFSQ.setPipelineHash('clearfsq');
      model.clearFSQ.setFragmentShaderTemplate(clearFragColorTemplate);

      var _ubo2 = vtkWebGPUUniformBuffer.newInstance({
        label: 'mapperUBO'
      });

      _ubo2.addEntry('FSQMatrix', 'mat4x4<f32>');

      _ubo2.addEntry('BackgroundColor', 'vec4<f32>');

      model.clearFSQ.setUBO(_ubo2);
    }

    var keyMats = model.webgpuCamera.getKeyMatrices(publicAPI);
    var background = model.renderable.getBackgroundByReference();
    model.clearFSQ.getUBO().setArray('BackgroundColor', background);
    mat4.transpose(_tNormalMat4, keyMats.normalMatrix);
    mat4.mul(_fsqClearMat4, keyMats.scvc, keyMats.pcsc);
    mat4.mul(_fsqClearMat4, _tNormalMat4, _fsqClearMat4);
    model.clearFSQ.getUBO().setArray('FSQMatrix', _fsqClearMat4);
    model.clearFSQ.getUBO().sendIfNeeded(device);
    model.clearFSQ.prepareAndDraw(model.renderEncoder);
  };

  publicAPI.translucentPass = function (prepass) {
    if (prepass) {
      model.renderEncoder.begin(model._parent.getCommandEncoder());
    } else {
      publicAPI.scissorAndViewport(model.renderEncoder);
      model.renderEncoder.end();
    }
  };

  publicAPI.volumeDepthRangePass = function (prepass) {
    if (prepass) {
      model.renderEncoder.begin(model._parent.getCommandEncoder());
    } else {
      publicAPI.scissorAndViewport(model.renderEncoder);
      model.renderEncoder.end();
    }
  };

  publicAPI.getAspectRatio = function () {
    var size = model._parent.getSizeByReference();

    var viewport = model.renderable.getViewportByReference();
    return size[0] * (viewport[2] - viewport[0]) / ((viewport[3] - viewport[1]) * size[1]);
  };

  publicAPI.convertToOpenGLDepth = function (val) {
    return model.webgpuCamera.convertToOpenGLDepth(val);
  };

  publicAPI.getYInvertedTiledSizeAndOrigin = function () {
    var res = publicAPI.getTiledSizeAndOrigin();

    var size = model._parent.getSizeByReference();

    res.lowerLeftV = size[1] - res.vsize - res.lowerLeftV;
    return res;
  };

  publicAPI.getTiledSizeAndOrigin = function () {
    var vport = model.renderable.getViewportByReference(); // if there is no window assume 0 1

    var tileViewPort = [0.0, 0.0, 1.0, 1.0]; // find the lower left corner of the viewport, taking into account the
    // lower left boundary of this tile

    var vpu = vport[0] - tileViewPort[0];
    var vpv = vport[1] - tileViewPort[1]; // store the result as a pixel value

    var ndvp = model._parent.normalizedDisplayToDisplay(vpu, vpv);

    var lowerLeftU = Math.round(ndvp[0]);
    var lowerLeftV = Math.round(ndvp[1]); // find the upper right corner of the viewport, taking into account the
    // lower left boundary of this tile

    var vpu2 = vport[2] - tileViewPort[0];
    var vpv2 = vport[3] - tileViewPort[1];

    var ndvp2 = model._parent.normalizedDisplayToDisplay(vpu2, vpv2); // now compute the size of the intersection of the viewport with the
    // current tile


    var usize = Math.round(ndvp2[0]) - lowerLeftU;
    var vsize = Math.round(ndvp2[1]) - lowerLeftV;

    if (usize < 0) {
      usize = 0;
    }

    if (vsize < 0) {
      vsize = 0;
    }

    return {
      usize: usize,
      vsize: vsize,
      lowerLeftU: lowerLeftU,
      lowerLeftV: lowerLeftV
    };
  };

  publicAPI.getPropFromID = function (id) {
    for (var i = 0; i < model.children.length; i++) {
      var res = model.children[i].getPropID ? model.children[i].getPropID() : -1;

      if (res === id) {
        return model.children[i];
      }
    }

    return null;
  };

  publicAPI.getStabilizedTime = function () {
    return model.stabilizedTime.getMTime();
  };

  publicAPI.releaseGraphicsResources = function () {
    if (model.selector !== null) {
      model.selector.releaseGraphicsResources();
    }
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  bindGroup: null,
  selector: null,
  renderEncoder: null,
  recenterThreshold: 20.0,
  suppressClear: false,
  stabilizedCenter: [0.0, 0.0, 0.0]
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Inheritance

  vtkViewNode.extend(publicAPI, model, initialValues); // UBO

  model.UBO = vtkWebGPUUniformBuffer.newInstance({
    label: 'rendererUBO'
  });
  model.UBO.addEntry('WCVCMatrix', 'mat4x4<f32>');
  model.UBO.addEntry('SCPCMatrix', 'mat4x4<f32>');
  model.UBO.addEntry('PCSCMatrix', 'mat4x4<f32>');
  model.UBO.addEntry('SCVCMatrix', 'mat4x4<f32>');
  model.UBO.addEntry('VCPCMatrix', 'mat4x4<f32>');
  model.UBO.addEntry('WCVCNormals', 'mat4x4<f32>');
  model.UBO.addEntry('viewportSize', 'vec2<f32>');
  model.UBO.addEntry('LightCount', 'i32');
  model.UBO.addEntry('MaxEnvironmentMipLevel', 'f32');
  model.UBO.addEntry('BackgroundDiffuseStrength', 'f32');
  model.UBO.addEntry('BackgroundSpecularStrength', 'f32');
  model.UBO.addEntry('cameraParallel', 'u32'); // SSBO (Light data)

  model.SSBO = vtkWebGPUStorageBuffer.newInstance({
    label: 'rendererLightSSBO'
  });
  model.lightTimeString = '';
  model.bindGroup = vtkWebGPUBindGroup.newInstance({
    label: 'rendererBG'
  });
  model.bindGroup.setBindables([model.UBO, model.SSBO]);
  model.tmpMat4 = mat4.identity(new Float64Array(16));
  model.stabilizedTime = {};
  obj(model.stabilizedTime, {
    mtime: 0
  }); // Build VTK API

  get(publicAPI, model, ['bindGroup', 'stabilizedTime']);
  getArray(publicAPI, model, ['stabilizedCenter']);
  setGet(publicAPI, model, ['renderEncoder', 'selector', 'suppressClear', 'UBO']); // Object methods

  vtkWebGPURenderer(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = newInstance$1(extend, 'vtkWebGPURenderer'); // ----------------------------------------------------------------------------

var index = {
  newInstance: newInstance,
  extend: extend
}; // Register ourself to WebGPU backend if imported

registerOverride('vtkRenderer', newInstance);

export { index as default, extend, newInstance };
