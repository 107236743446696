import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { newInstance as newInstance$1, obj, algo, setGet, vtkErrorMacro as vtkErrorMacro$1, newTypedArrayFrom } from '../../macros.js';
import vtkPolyData from '../../Common/DataModel/PolyData.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var vtkErrorMacro = vtkErrorMacro$1;

function initPolyIterator(pd) {
  var polys = pd.getPolys().getData();
  var strips = pd.getStrips().getData();
  var it = {
    done: false,
    polyIdx: 0,
    stripIdx: 0,
    remainingStripLength: 0,
    // returns a single poly cell
    next: function next() {
      var ret = null;

      if (it.polyIdx < polys.length) {
        var cellSize = polys[it.polyIdx];
        var start = it.polyIdx + 1;
        var end = start + cellSize;
        it.polyIdx = end;
        ret = polys.subarray(start, end);
      } else if (it.stripIdx < strips.length) {
        if (it.remainingStripLength === 0) {
          it.remainingStripLength = strips[it.stripIdx] - 2; // sliding window of 3 points
          // stripIdx points to the last point in a triangle 3-tuple

          it.stripIdx += 3;
        }

        var _start = it.stripIdx - 2;

        var _end = it.stripIdx + 1;

        it.stripIdx++;
        it.remainingStripLength--;
        ret = strips.subarray(_start, _end);
      } else if (it.done) {
        throw new Error('Iterator is done');
      }

      it.done = it.polyIdx >= polys.length && it.stripIdx >= strips.length;
      return ret;
    }
  };
  return it;
} // ----------------------------------------------------------------------------
// vtkCutter methods
// ----------------------------------------------------------------------------


function vtkCutter(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkCutter'); // Capture "parentClass" api for internal use

  var superClass = _objectSpread({}, publicAPI);

  publicAPI.getMTime = function () {
    var mTime = superClass.getMTime();

    if (!model.cutFunction) {
      return mTime;
    }

    mTime = Math.max(mTime, model.cutFunction.getMTime());
    return mTime;
  };

  function dataSetCutter(input, output) {
    var points = input.getPoints();
    var pointsData = points.getData();
    var numPts = points.getNumberOfPoints();
    var newPointsData = [];
    var newLinesData = [];
    var newPolysData = [];

    if (!model.cutScalars || model.cutScalars.length < numPts) {
      model.cutScalars = new Float32Array(numPts);
    } // Loop over all points evaluating scalar function at each point


    var inOffset = 0;
    var outOffset = 0;

    while (inOffset < pointsData.length) {
      model.cutScalars[outOffset++] = model.cutFunction.evaluateFunction(pointsData[inOffset++], pointsData[inOffset++], pointsData[inOffset++]);
    }

    var crossedEdges = [];
    var x1 = new Array(3);
    var x2 = new Array(3); // Loop over all cells; get scalar values for all cell points
    // and process each cell.

    /* eslint-disable no-continue */

    var it = initPolyIterator(input);

    while (!it.done) {
      // cell contains the point IDs/indices
      var cell = it.next(); // Check that cells have at least 3 points

      if (cell.length <= 2) {
        continue;
      } // Get associated scalar of points that constitute the current cell


      var cellPointsScalars = [];
      var pointIndex = void 0;

      for (var i = 0; i < cell.length; i++) {
        pointIndex = cell[i];
        cellPointsScalars.push(model.cutScalars[pointIndex]);
      } // Check if all cell points are on same side (same side == cell not crossed by cut function)
      // TODO: won't work if one point scalar is = 0 ?


      var sideFirstPoint = cellPointsScalars[0] > 0;
      var allPointsSameSide = true;

      for (var _i = 1; _i < cellPointsScalars.length; _i++) {
        var sideCurrentPoint = cellPointsScalars[_i] > 0;

        if (sideCurrentPoint !== sideFirstPoint) {
          allPointsSameSide = false;
          break;
        }
      } // Go to next cell if cell is not crossed by cut function


      if (allPointsSameSide) {
        continue;
      } // Find and compute edges which intersect cells


      var intersectedEdgesList = [];

      for (var _i2 = 0; _i2 < cell.length; _i2++) {
        var idNext = _i2 + 1 === cell.length ? 0 : _i2 + 1; // Go to next edge if edge is not crossed
        // TODO: in most come cases, (numberOfPointsInCell - 1) or 0 edges of the cell
        // will be crossed, but if it crosses right at a point, it could be intersecting
        // with (numberOfPoints) or 1 edge(s). Do we account for that?

        var signPoint0 = cellPointsScalars[_i2] > 0;
        var signPoint1 = cellPointsScalars[idNext] > 0;

        if (signPoint1 === signPoint0) {
          continue;
        } // Compute preferred interpolation direction


        var e1 = _i2;
        var e2 = idNext;
        var deltaScalar = cellPointsScalars[e2] - cellPointsScalars[e1];

        if (deltaScalar <= 0) {
          e1 = idNext;
          e2 = _i2;
          deltaScalar *= -1;
        } // linear interpolation


        var t = 0.0;

        if (deltaScalar !== 0.0) {
          t = (model.cutValue - cellPointsScalars[e1]) / deltaScalar;
        } // points position


        var pointID1 = cell[e1];
        var pointID2 = cell[e2];
        x1[0] = pointsData[pointID1 * 3];
        x1[1] = pointsData[pointID1 * 3 + 1];
        x1[2] = pointsData[pointID1 * 3 + 2];
        x2[0] = pointsData[pointID2 * 3];
        x2[1] = pointsData[pointID2 * 3 + 1];
        x2[2] = pointsData[pointID2 * 3 + 2]; // Compute the intersected point on edge

        var computedIntersectedPoint = [x1[0] + t * (x2[0] - x1[0]), x1[1] + t * (x2[1] - x1[1]), x1[2] + t * (x2[2] - x1[2])]; // Keep track of it

        intersectedEdgesList.push({
          pointEdge1: pointID1,
          // id of one point of the edge
          pointEdge2: pointID2,
          // id of one point of the edge
          intersectedPoint: computedIntersectedPoint,
          // 3D coordinate of points that intersected edge
          newPointID: -1 // id of the intersected point when it will be added into vtkPoints

        });
      } // Add points into newPointList


      for (var _i3 = 0; _i3 < intersectedEdgesList.length; _i3++) {
        var intersectedEdge = intersectedEdgesList[_i3];
        var alreadyAdded = false; // Check if point/edge already added

        for (var j = 0; j < crossedEdges.length; j++) {
          var crossedEdge = crossedEdges[j];
          var sameEdge = intersectedEdge.pointEdge1 === crossedEdge.pointEdge1 && intersectedEdge.pointEdge2 === crossedEdge.pointEdge2;
          var samePoint = intersectedEdge.intersectedPoint[0] === crossedEdge.intersectedPoint[0] && intersectedEdge.intersectedPoint[1] === crossedEdge.intersectedPoint[1] && intersectedEdge.intersectedPoint[2] === crossedEdge.intersectedPoint[2];

          if (sameEdge || samePoint) {
            alreadyAdded = true;
            intersectedEdgesList[_i3].newPointID = crossedEdges[j].newPointID;
            break;
          }
        }

        if (!alreadyAdded) {
          newPointsData.push(intersectedEdge.intersectedPoint[0]);
          newPointsData.push(intersectedEdge.intersectedPoint[1]);
          newPointsData.push(intersectedEdge.intersectedPoint[2]);
          intersectedEdgesList[_i3].newPointID = newPointsData.length / 3 - 1;
          crossedEdges.push(intersectedEdgesList[_i3]);
        }
      } // Store cells


      var cellSize = intersectedEdgesList.length;

      if (cellSize === 2) {
        newLinesData.push(cellSize, intersectedEdgesList[0].newPointID, intersectedEdgesList[1].newPointID);
      } else if (cellSize > 2) {
        newPolysData.push(cellSize);
        intersectedEdgesList.forEach(function (edge) {
          newPolysData.push(edge.newPointID);
        });
      }
    } // Set points


    var outputPoints = output.getPoints();
    outputPoints.setData(newTypedArrayFrom(points.getDataType(), newPointsData), 3); // Set lines

    if (newLinesData.length !== 0) {
      output.getLines().setData(Uint16Array.from(newLinesData));
    } // Set polys


    if (newPolysData.length !== 0) {
      output.getPolys().setData(Uint16Array.from(newPolysData));
    }
  } // expose requestData


  publicAPI.requestData = function (inData, outData) {
    // implement requestData
    var input = inData[0];

    if (!input) {
      vtkErrorMacro('Invalid or missing input');
      return;
    }

    if (!model.cutFunction) {
      vtkErrorMacro('Missing cut function');
      return;
    }

    var output = vtkPolyData.newInstance();
    dataSetCutter(input, output);
    outData[0] = output;
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  cutFunction: null,
  // support method with evaluateFunction method
  cutScalars: null,
  cutValue: 0.0
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Make this a VTK object

  obj(publicAPI, model); // Also make it an algorithm with one input and one output

  algo(publicAPI, model, 1, 1); // Set implicit function use to cut the input data (is vtkPlane)

  setGet(publicAPI, model, ['cutFunction', 'cutValue']); // Object specific methods

  vtkCutter(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = newInstance$1(extend, 'vtkCutter'); // ----------------------------------------------------------------------------

var vtkCutter$1 = {
  newInstance: newInstance,
  extend: extend
};

export { vtkCutter$1 as default, extend, newInstance };
