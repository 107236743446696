import { mat4 } from 'gl-matrix';
import macro from '../../macros.js';
import vtkProp from '../Core/Prop.js';
import vtkViewNode from '../SceneGraph/ViewNode.js';
import { registerOverride } from './ViewNodeFactory.js';

var CoordinateSystem = vtkProp.CoordinateSystem; // ----------------------------------------------------------------------------
// vtkWebGPUActor methods
// ----------------------------------------------------------------------------

function vtkWebGPUActor(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkWebGPUActor'); // Builds myself.

  publicAPI.buildPass = function (prepass) {
    if (prepass) {
      model.WebGPURenderer = publicAPI.getFirstAncestorOfType('vtkWebGPURenderer');
      model.WebGPURenderWindow = model.WebGPURenderer.getFirstAncestorOfType('vtkWebGPURenderWindow');

      if (model.propID === undefined) {
        model.propID = model.WebGPURenderWindow.getUniquePropID();
      }

      publicAPI.prepareNodes();
      publicAPI.addMissingNode(model.renderable.getMapper());
      publicAPI.removeUnusedNodes();
    }
  }; // we draw textures, then mapper, then post pass textures


  publicAPI.traverseOpaquePass = function (renderPass) {
    if (!model.renderable || !model.renderable.getNestedVisibility() || !model.renderable.getIsOpaque() || model.WebGPURenderer.getSelector() && !model.renderable.getNestedPickable()) {
      return;
    }

    publicAPI.apply(renderPass, true);

    if (model.children[0]) {
      model.children[0].traverse(renderPass);
    }

    publicAPI.apply(renderPass, false);
  }; // we draw textures, then mapper, then post pass textures


  publicAPI.traverseTranslucentPass = function (renderPass) {
    if (!model.renderable || !model.renderable.getNestedVisibility() || model.renderable.getIsOpaque() || model.WebGPURenderer.getSelector() && !model.renderable.getNestedPickable()) {
      return;
    }

    publicAPI.apply(renderPass, true);

    if (model.children[0]) {
      model.children[0].traverse(renderPass);
    }

    publicAPI.apply(renderPass, false);
  };

  publicAPI.queryPass = function (prepass, renderPass) {
    if (prepass) {
      if (!model.renderable || !model.renderable.getVisibility()) {
        return;
      }

      if (model.renderable.getIsOpaque()) {
        renderPass.incrementOpaqueActorCount();
      } else {
        renderPass.incrementTranslucentActorCount();
      }
    }
  };

  publicAPI.getBufferShift = function (wgpuRen) {
    publicAPI.getKeyMatrices(wgpuRen);
    return model.bufferShift;
  };

  publicAPI.getKeyMatrices = function (wgpuRen) {
    // has the actor or stabilization center changed?
    if (Math.max(model.renderable.getMTime(), wgpuRen.getStabilizedTime()) > model.keyMatricesTime.getMTime()) {
      model.renderable.computeMatrix();
      var mcwc = model.renderable.getMatrix(); // compute the net shift, only apply stabilized coords with world coordinates

      model.bufferShift[0] = mcwc[3];
      model.bufferShift[1] = mcwc[7];
      model.bufferShift[2] = mcwc[11];
      var center = wgpuRen.getStabilizedCenterByReference();

      if (model.renderable.getCoordinateSystem() === CoordinateSystem.WORLD) {
        model.bufferShift[0] -= center[0];
        model.bufferShift[1] -= center[1];
        model.bufferShift[2] -= center[2];
      }

      mat4.transpose(model.keyMatrices.bcwc, mcwc);

      if (model.renderable.getIsIdentity()) {
        mat4.identity(model.keyMatrices.normalMatrix);
      } else {
        // we use bcwc BEFORE the translate below (just to get transposed mcvc)
        mat4.copy(model.keyMatrices.normalMatrix, model.keyMatrices.bcwc); // zero out translation

        model.keyMatrices.normalMatrix[3] = 0.0;
        model.keyMatrices.normalMatrix[7] = 0.0;
        model.keyMatrices.normalMatrix[11] = 0.0;
        mat4.invert(model.keyMatrices.normalMatrix, model.keyMatrices.normalMatrix);
        mat4.transpose(model.keyMatrices.normalMatrix, model.keyMatrices.normalMatrix);
      } // only need the buffer shift to get to world


      mat4.translate(model.keyMatrices.bcwc, model.keyMatrices.bcwc, [-model.bufferShift[0], -model.bufferShift[1], -model.bufferShift[2]]); // to get to stabilized we also need the center

      if (model.renderable.getCoordinateSystem() === CoordinateSystem.WORLD) {
        mat4.translate(model.keyMatrices.bcsc, model.keyMatrices.bcwc, [-center[0], -center[1], -center[2]]);
      } else {
        mat4.copy(model.keyMatrices.bcsc, model.keyMatrices.bcwc);
      }

      model.keyMatricesTime.modified();
    }

    return model.keyMatrices;
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  keyMatricesTime: null,
  keyMatrices: null,
  propID: undefined,
  bufferShift: undefined
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Inheritance

  vtkViewNode.extend(publicAPI, model, initialValues);
  model.keyMatricesTime = {};
  macro.obj(model.keyMatricesTime, {
    mtime: 0
  });
  model.keyMatrices = {
    normalMatrix: new Float64Array(16),
    bcwc: new Float64Array(16),
    bcsc: new Float64Array(16)
  };
  macro.get(publicAPI, model, ['propID', 'keyMatricesTime']);
  model.bufferShift = [0, 0, 0, 0]; // Object methods

  vtkWebGPUActor(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend); // ----------------------------------------------------------------------------

var index = {
  newInstance: newInstance,
  extend: extend
}; // Register ourself to WebGPU backend if imported

registerOverride('vtkActor', newInstance);

export { index as default, extend, newInstance };
