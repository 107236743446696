import _defineProperty from '@babel/runtime/helpers/defineProperty';
import macro from '../../macros.js';
import Constants from './Prop/Constants.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var CoordinateSystem = Constants.CoordinateSystem;

function notImplemented(method) {
  return function () {
    return macro.vtkErrorMacro("vtkProp::".concat(method, " - NOT IMPLEMENTED"));
  };
} // ----------------------------------------------------------------------------
// vtkProp methods
// ----------------------------------------------------------------------------


function vtkProp(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkProp');

  publicAPI.getMTime = function () {
    var m1 = model.mtime;

    for (var index = 0; index < model.textures.length; ++index) {
      var m2 = model.textures[index].getMTime();

      if (m2 > m1) {
        m1 = m2;
      }
    }

    return m1;
  };

  publicAPI.processSelectorPixelBuffers = function (selector, pixeloffsets) {};

  publicAPI.getNestedProps = function () {
    return null;
  };

  publicAPI.getActors = function () {
    return [];
  };

  publicAPI.getActors2D = function () {
    return [];
  };

  publicAPI.getVolumes = function () {
    return [];
  };

  publicAPI.pick = notImplemented('pick');
  publicAPI.hasKey = notImplemented('hasKey');

  publicAPI.getNestedVisibility = function () {
    return model.visibility && (!model._parentProp || model._parentProp.getNestedVisibility());
  };

  publicAPI.getNestedPickable = function () {
    return model.pickable && (!model._parentProp || model._parentProp.getNestedPickable());
  };

  publicAPI.getNestedDragable = function () {
    return model.dragable && (!model._parentProp || model._parentProp.getNestedDragable());
  };

  publicAPI.getRedrawMTime = function () {
    return model.mtime;
  };

  publicAPI.setEstimatedRenderTime = function (t) {
    model.estimatedRenderTime = t;
    model.savedEstimatedRenderTime = t;
  };

  publicAPI.restoreEstimatedRenderTime = function () {
    model.estimatedRenderTime = model.savedEstimatedRenderTime;
  };

  publicAPI.addEstimatedRenderTime = function (t) {
    model.estimatedRenderTime += t;
  };

  publicAPI.setAllocatedRenderTime = function (t) {
    model.allocatedRenderTime = t;
    model.savedEstimatedRenderTime = model.estimatedRenderTime;
    model.estimatedRenderTime = 0;
  };

  publicAPI.getSupportsSelection = function () {
    return false;
  };

  publicAPI.getTextures = function () {
    return model.textures;
  };

  publicAPI.hasTexture = function (texture) {
    return model.textures.indexOf(texture) !== -1;
  };

  publicAPI.addTexture = function (texture) {
    if (texture && !publicAPI.hasTexture(texture)) {
      model.textures = model.textures.concat(texture);
      publicAPI.modified();
    }
  };

  publicAPI.removeTexture = function (texture) {
    var newTextureList = model.textures.filter(function (item) {
      return item !== texture;
    });

    if (model.textures.length !== newTextureList.length) {
      model.textures = newTextureList;
      publicAPI.modified();
    }
  };

  publicAPI.removeAllTextures = function () {
    model.textures = [];
    publicAPI.modified();
  }; // not all mappers support all coordinate systems


  publicAPI.setCoordinateSystemToWorld = function () {
    return publicAPI.setCoordinateSystem(CoordinateSystem.WORLD);
  };

  publicAPI.setCoordinateSystemToDisplay = function () {
    return publicAPI.setCoordinateSystem(CoordinateSystem.DISPLAY);
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  // _parentProp: null,
  allocatedRenderTime: 10,
  coordinateSystem: CoordinateSystem.WORLD,
  dragable: true,
  estimatedRenderTime: 0,
  paths: null,
  pickable: true,
  renderTimeMultiplier: 1,
  savedEstimatedRenderTime: 0,
  textures: [],
  useBounds: true,
  visibility: true
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Build VTK API

  macro.obj(publicAPI, model);
  macro.get(publicAPI, model, ['estimatedRenderTime', 'allocatedRenderTime']);
  macro.setGet(publicAPI, model, ['_parentProp', 'coordinateSystem', 'dragable', 'pickable', 'renderTimeMultiplier', 'useBounds', 'visibility']);
  macro.moveToProtected(publicAPI, model, ['parentProp']); // Object methods

  vtkProp(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkProp'); // ----------------------------------------------------------------------------

var vtkProp$1 = _objectSpread({
  newInstance: newInstance,
  extend: extend
}, Constants);

export { vtkProp$1 as default, extend, newInstance };
