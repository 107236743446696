import { newInstance as newInstance$1 } from '../../macros.js';
import vtkWebGPUBufferManager from './BufferManager.js';
import vtkWebGPUCellArrayMapper from './CellArrayMapper.js';
import vtkViewNode from '../SceneGraph/ViewNode.js';
import { registerOverride } from './ViewNodeFactory.js';

var PrimitiveTypes = vtkWebGPUBufferManager.PrimitiveTypes; // ----------------------------------------------------------------------------
// vtkWebGPUPolyDataMapper methods
// ----------------------------------------------------------------------------

function vtkWebGPUPolyDataMapper(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkWebGPUPolyDataMapper');

  publicAPI.createCellArrayMapper = function () {
    return vtkWebGPUCellArrayMapper.newInstance();
  };

  publicAPI.buildPass = function (prepass) {
    if (prepass) {
      model.WebGPUActor = publicAPI.getFirstAncestorOfType('vtkWebGPUActor');

      if (!model.renderable.getStatic()) {
        model.renderable.update();
      }

      var poly = model.renderable.getInputData();
      model.renderable.mapScalars(poly, 1.0);
      publicAPI.updateCellArrayMappers(poly);
    }
  };

  publicAPI.updateCellArrayMappers = function (poly) {
    var prims = [poly.getVerts(), poly.getLines(), poly.getPolys(), poly.getStrips()]; // we instantiate a cell array mapper for each cellArray that has cells
    // and they handle the rendering of that cell array

    var cellMappers = [];
    var cellOffset = 0;

    for (var i = PrimitiveTypes.Points; i <= PrimitiveTypes.Triangles; i++) {
      if (prims[i].getNumberOfValues() > 0) {
        if (!model.primitives[i]) {
          model.primitives[i] = publicAPI.createCellArrayMapper();
        }

        var cellMapper = model.primitives[i];
        cellMapper.setCellArray(prims[i]);
        cellMapper.setCurrentInput(poly);
        cellMapper.setCellOffset(cellOffset);
        cellMapper.setPrimitiveType(i);
        cellMapper.setRenderable(model.renderable);
        cellOffset += prims[i].getNumberOfCells();
        cellMappers.push(cellMapper);
      } else {
        model.primitives[i] = null;
      }
    }

    if (model.WebGPUActor.getRenderable().getProperty().getEdgeVisibility()) {
      for (var _i = PrimitiveTypes.TriangleEdges; _i <= PrimitiveTypes.TriangleStripEdges; _i++) {
        if (prims[_i - 2].getNumberOfValues() > 0) {
          if (!model.primitives[_i]) {
            model.primitives[_i] = publicAPI.createCellArrayMapper();
          }

          var _cellMapper = model.primitives[_i];

          _cellMapper.setCellArray(prims[_i - 2]);

          _cellMapper.setCurrentInput(poly);

          _cellMapper.setCellOffset(model.primitives[_i - 2].getCellOffset());

          _cellMapper.setPrimitiveType(_i);

          _cellMapper.setRenderable(model.renderable);

          cellMappers.push(_cellMapper);
        } else {
          model.primitives[_i] = null;
        }
      }
    }

    publicAPI.prepareNodes();
    publicAPI.addMissingChildren(cellMappers);
    publicAPI.removeUnusedNodes();
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  primitives: null
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Inheritance

  vtkViewNode.extend(publicAPI, model, initialValues);
  model.primitives = []; // Object methods

  vtkWebGPUPolyDataMapper(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = newInstance$1(extend, 'vtkWebGPUPolyDataMapper'); // ----------------------------------------------------------------------------

var vtkWebGPUPolyDataMapper$1 = {
  newInstance: newInstance,
  extend: extend
}; // Register ourself to WebGPU backend if imported

registerOverride('vtkMapper', newInstance);

export { vtkWebGPUPolyDataMapper$1 as default, extend, newInstance };
