import vtk from '../../../vtk.js';
import macro from '../../../macros.js';
import vtkDataArray from '../../Core/DataArray.js';

var vtkErrorMacro = macro.vtkErrorMacro,
    vtkWarningMacro = macro.vtkWarningMacro; // ----------------------------------------------------------------------------
// vtkFieldData methods
// ----------------------------------------------------------------------------

function vtkFieldData(publicAPI, model) {
  model.classHierarchy.push('vtkFieldData');
  var superGetState = publicAPI.getState; // Decode serialized data if any

  if (model.arrays) {
    model.arrays = model.arrays.map(function (item) {
      return {
        data: vtk(item.data)
      };
    });
  }

  publicAPI.initialize = function () {
    publicAPI.initializeFields();
    publicAPI.copyAllOn();
    publicAPI.clearFieldFlags();
  };

  publicAPI.initializeFields = function () {
    model.arrays = [];
    model.copyFieldFlags = {};
    publicAPI.modified();
  };

  publicAPI.copyStructure = function (other) {
    publicAPI.initializeFields();
    model.copyFieldFlags = other.getCopyFieldFlags().map(function (x) {
      return x;
    }); // Deep-copy

    model.arrays = other.arrays().map(function (x) {
      return {
        array: x
      };
    }); // Deep-copy
    // TODO: Copy array information objects (once we support information objects)
  };

  publicAPI.getNumberOfArrays = function () {
    return model.arrays.length;
  };

  publicAPI.getNumberOfActiveArrays = function () {
    return model.arrays.length;
  };

  publicAPI.addArray = function (arr) {
    var name = arr.getName();

    var _publicAPI$getArrayWi = publicAPI.getArrayWithIndex(name),
        array = _publicAPI$getArrayWi.array,
        index = _publicAPI$getArrayWi.index;

    if (array != null) {
      model.arrays[index] = {
        data: arr
      };
      return index;
    }

    model.arrays = [].concat(model.arrays, {
      data: arr
    });
    return model.arrays.length - 1;
  };

  publicAPI.removeAllArrays = function () {
    model.arrays = [];
  };

  publicAPI.removeArray = function (arrayName) {
    var index = model.arrays.findIndex(function (array) {
      return array.getName() === arrayName;
    });
    return publicAPI.removeArrayByIndex(index);
  };

  publicAPI.removeArrayByIndex = function (arrayIdx) {
    if (arrayIdx !== -1 && arrayIdx < model.arrays.length) {
      model.arrays.splice(arrayIdx, 1); // TBD modified() ?

      return true;
    }

    return false;
  };

  publicAPI.getArrays = function () {
    return model.arrays.map(function (entry) {
      return entry.data;
    });
  };

  publicAPI.getArray = function (arraySpec) {
    return typeof arraySpec === 'number' ? publicAPI.getArrayByIndex(arraySpec) : publicAPI.getArrayByName(arraySpec);
  };

  publicAPI.getArrayByName = function (arrayName) {
    return model.arrays.reduce(function (a, b, i) {
      return b.data.getName() === arrayName ? b.data : a;
    }, null);
  };

  publicAPI.getArrayWithIndex = function (arrayName) {
    var index = model.arrays.findIndex(function (array) {
      return array.data.getName() === arrayName;
    });
    return {
      array: index !== -1 ? model.arrays[index].data : null,
      index: index
    };
  };

  publicAPI.getArrayByIndex = function (idx) {
    return idx >= 0 && idx < model.arrays.length ? model.arrays[idx].data : null;
  };

  publicAPI.hasArray = function (arrayName) {
    return publicAPI.getArrayWithIndex(arrayName).index >= 0;
  };

  publicAPI.getArrayName = function (idx) {
    var arr = model.arrays[idx];
    return arr ? arr.data.getName() : '';
  };

  publicAPI.getCopyFieldFlags = function () {
    return model.copyFieldFlags;
  };

  publicAPI.getFlag = function (arrayName) {
    return model.copyFieldFlags[arrayName];
  };

  publicAPI.passData = function (other) {
    var fromId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;
    var toId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : -1;
    other.getArrays().forEach(function (arr) {
      var copyFlag = publicAPI.getFlag(arr.getName());

      if (copyFlag !== false && !(model.doCopyAllOff && copyFlag !== true) && arr) {
        var destArr = publicAPI.getArrayByName(arr.getName());

        if (!destArr) {
          if (fromId < 0 || fromId > arr.getNumberOfTuples()) {
            // TBD: should this be a deep or a shallow copy?
            publicAPI.addArray(arr);
            other.getAttributes(arr).forEach(function (attrType) {
              publicAPI.setAttribute(arr, attrType);
            });
          } else {
            var ncomps = arr.getNumberOfComponents();
            var newSize = arr.getNumberOfValues();
            var tId = toId > -1 ? toId : fromId;

            if (newSize <= tId * ncomps) {
              newSize = (tId + 1) * ncomps;
            }

            destArr = vtkDataArray.newInstance({
              name: arr.getName(),
              dataType: arr.getDataType(),
              numberOfComponents: ncomps,
              values: macro.newTypedArray(arr.getDataType(), newSize),
              size: 0
            });
            destArr.insertTuple(tId, arr.getTuple(fromId));
            publicAPI.addArray(destArr);
            other.getAttributes(arr).forEach(function (attrType) {
              publicAPI.setAttribute(destArr, attrType);
            });
          }
        } else if (arr.getNumberOfComponents() === destArr.getNumberOfComponents()) {
          if (fromId > -1 && fromId < arr.getNumberOfTuples()) {
            var _tId = toId > -1 ? toId : fromId;

            destArr.insertTuple(_tId, arr.getTuple(fromId));
          } else {
            // if `fromId` is not provided, just copy all (or as much possible)
            // from `arr` to `destArr`.
            destArr.insertTuples(0, arr.getTuples());
          }
        } else {
          vtkErrorMacro('Unhandled case in passData');
        }
      }
    });
  };

  publicAPI.interpolateData = function (other) {
    var fromId1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;
    var fromId2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : -1;
    var toId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : -1;
    var t = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0.5;
    other.getArrays().forEach(function (arr) {
      var copyFlag = publicAPI.getFlag(arr.getName());

      if (copyFlag !== false && !(model.doCopyAllOff && copyFlag !== true) && arr) {
        var destArr = publicAPI.getArrayByName(arr.getName());

        if (!destArr) {
          if (fromId1 < 0 || fromId2 < 0 || fromId1 > arr.getNumberOfTuples()) {
            // TBD: should this be a deep or a shallow copy?
            publicAPI.addArray(arr);
            other.getAttributes(arr).forEach(function (attrType) {
              publicAPI.setAttribute(arr, attrType);
            });
          } else {
            var ncomps = arr.getNumberOfComponents();
            var newSize = arr.getNumberOfValues(); // TODO: Is this supposed to happen?

            var tId = toId > -1 ? toId : fromId1;

            if (newSize <= tId * ncomps) {
              newSize = (tId + 1) * ncomps;
            }

            destArr = vtkDataArray.newInstance({
              name: arr.getName(),
              dataType: arr.getDataType(),
              numberOfComponents: ncomps,
              values: macro.newTypedArray(arr.getDataType(), newSize),
              size: 0
            });
            destArr.interpolateTuple(tId, arr, fromId1, arr, fromId2, t);
            publicAPI.addArray(destArr);
            other.getAttributes(arr).forEach(function (attrType) {
              publicAPI.setAttribute(destArr, attrType);
            });
          }
        } else if (arr.getNumberOfComponents() === destArr.getNumberOfComponents()) {
          if (fromId1 > -1 && fromId1 < arr.getNumberOfTuples()) {
            var _tId2 = toId > -1 ? toId : fromId1;

            destArr.interpolateTuple(_tId2, arr, fromId1, arr, fromId2, t);
            vtkWarningMacro('Unexpected case in interpolateData');
          } else {
            // if `fromId` is not provided, just copy all (or as much possible)
            // from `arr` to `destArr`.
            destArr.insertTuples(arr.getTuples());
          }
        } else {
          vtkErrorMacro('Unhandled case in interpolateData');
        }
      }
    });
  };

  publicAPI.copyFieldOn = function (arrayName) {
    model.copyFieldFlags[arrayName] = true;
  };

  publicAPI.copyFieldOff = function (arrayName) {
    model.copyFieldFlags[arrayName] = false;
  };

  publicAPI.copyAllOn = function () {
    if (!model.doCopyAllOn || model.doCopyAllOff) {
      model.doCopyAllOn = true;
      model.doCopyAllOff = false;
      publicAPI.modified();
    }
  };

  publicAPI.copyAllOff = function () {
    if (model.doCopyAllOn || !model.doCopyAllOff) {
      model.doCopyAllOn = false;
      model.doCopyAllOff = true;
      publicAPI.modified();
    }
  };

  publicAPI.clearFieldFlags = function () {
    model.copyFieldFlags = {};
  };

  publicAPI.deepCopy = function (other) {
    model.arrays = other.getArrays().map(function (arr) {
      var arrNew = arr.newClone();
      arrNew.deepCopy(arr);
      return {
        data: arrNew
      };
    });
  };

  publicAPI.copyFlags = function (other) {
    return other.getCopyFieldFlags().map(function (x) {
      return x;
    });
  }; // TODO: publicAPI.squeeze = () => model.arrays.forEach(entry => entry.data.squeeze());


  publicAPI.reset = function () {
    return model.arrays.forEach(function (entry) {
      return entry.data.reset();
    });
  }; // TODO: getActualMemorySize


  publicAPI.getMTime = function () {
    return model.arrays.reduce(function (a, b) {
      return b.data.getMTime() > a ? b.data.getMTime() : a;
    }, model.mtime);
  }; // TODO: publicAPI.getField = (ids, other) => { copy ids from other into this model's arrays }
  // TODO: publicAPI.getArrayContainingComponent = (component) => ...


  publicAPI.getNumberOfComponents = function () {
    return model.arrays.reduce(function (a, b) {
      return a + b.data.getNumberOfComponents();
    }, 0);
  };

  publicAPI.getNumberOfTuples = function () {
    return model.arrays.length > 0 ? model.arrays[0].getNumberOfTuples() : 0;
  };

  publicAPI.getState = function () {
    var result = superGetState();

    if (result) {
      result.arrays = model.arrays.map(function (item) {
        return {
          data: item.data.getState()
        };
      });
    }

    return result;
  };
}

var DEFAULT_VALUES = {
  arrays: [],
  copyFieldFlags: [],
  // fields not to copy
  doCopyAllOn: true,
  doCopyAllOff: false
};
function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues);
  macro.obj(publicAPI, model);
  vtkFieldData(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkFieldData'); // ----------------------------------------------------------------------------

var vtkFieldData$1 = {
  newInstance: newInstance,
  extend: extend
};

export { vtkFieldData$1 as default, extend, newInstance };
