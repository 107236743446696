import macro from '../../macros.js';
import vtkDataArray from '../../Common/Core/DataArray.js';
import vtkPoints from '../../Common/Core/Points.js';
import vtkPolyData from '../../Common/DataModel/PolyData.js';
import { DesiredOutputPrecision } from '../../Common/DataModel/DataSetAttributes/Constants.js';
import { VtkDataTypes } from '../../Common/Core/DataArray/Constants.js';

var vtkErrorMacro = macro.vtkErrorMacro;

function offsetCellArray(typedArray, offset) {
  var currentIdx = 0;
  return typedArray.map(function (value, index) {
    if (index === currentIdx) {
      currentIdx += value + 1;
      return value;
    }

    return value + offset;
  });
}

function appendCellData(dest, src, ptOffset, cellOffset) {
  dest.set(offsetCellArray(src, ptOffset), cellOffset);
} // ----------------------------------------------------------------------------
// vtkAppendPolyData methods
// ----------------------------------------------------------------------------


function vtkAppendPolyData(publicAPI, model) {
  // Set our classname
  model.classHierarchy.push('vtkAppendPolyData');

  publicAPI.requestData = function (inData, outData) {
    // implement requestData
    var numberOfInputs = publicAPI.getNumberOfInputPorts();

    if (!numberOfInputs) {
      vtkErrorMacro('No input specified.');
      return;
    }

    if (numberOfInputs === 1) {
      // pass through filter
      outData[0] = inData[0];
      return;
    } // Allocate output


    var output = vtkPolyData.newInstance();
    var numPts = 0;
    var pointType = 0;
    var ttype = 1;
    var firstType = 1;
    var numVerts = 0;
    var numLines = 0;
    var numStrips = 0;
    var numPolys = 0; // Field data is propagated to output only if present in all inputs

    var hasPtNormals = true; // assume present by default

    var hasPtTCoords = true;
    var hasPtScalars = true;

    for (var i = 0; i < numberOfInputs; i++) {
      var ds = inData[i];

      if (!ds) {
        // eslint-disable-next-line
        continue;
      }

      var dsNumPts = ds.getPoints().getNumberOfPoints();
      numPts += dsNumPts;
      numVerts += ds.getVerts().getNumberOfValues();
      numLines += ds.getLines().getNumberOfValues();
      numStrips += ds.getStrips().getNumberOfValues();
      numPolys += ds.getPolys().getNumberOfValues();

      if (dsNumPts) {
        if (firstType) {
          firstType = 0;
          pointType = ds.getPoints().getDataType();
        }

        ttype = ds.getPoints().getDataType();
        pointType = pointType > ttype ? pointType : ttype;
      }

      var ptD = ds.getPointData();

      if (ptD) {
        hasPtNormals = hasPtNormals && ptD.getNormals() !== null;
        hasPtTCoords = hasPtTCoords && ptD.getTCoords() !== null;
        hasPtScalars = hasPtScalars && ptD.getScalars() !== null;
      } else {
        hasPtNormals = false;
        hasPtTCoords = false;
        hasPtScalars = false;
      }
    }

    if (model.outputPointsPrecision === DesiredOutputPrecision.SINGLE) {
      pointType = VtkDataTypes.FLOAT;
    } else if (model.outputPointsPrecision === DesiredOutputPrecision.DOUBLE) {
      pointType = VtkDataTypes.DOUBLE;
    }

    var points = vtkPoints.newInstance({
      dataType: pointType
    });
    points.setNumberOfPoints(numPts);
    var pointData = points.getData();
    var vertData = new Uint32Array(numVerts);
    var lineData = new Uint32Array(numLines);
    var stripData = new Uint32Array(numStrips);
    var polyData = new Uint32Array(numPolys);
    var newPtNormals = null;
    var newPtTCoords = null;
    var newPtScalars = null;
    var lds = inData[numberOfInputs - 1];

    if (hasPtNormals) {
      var dsNormals = lds.getPointData().getNormals();
      newPtNormals = vtkDataArray.newInstance({
        numberOfComponents: 3,
        numberOfTuples: numPts,
        size: 3 * numPts,
        dataType: dsNormals.getDataType(),
        name: dsNormals.getName()
      });
    }

    if (hasPtTCoords) {
      var dsTCoords = lds.getPointData().getTCoords();
      newPtTCoords = vtkDataArray.newInstance({
        numberOfComponents: 2,
        numberOfTuples: numPts,
        size: 2 * numPts,
        dataType: dsTCoords.getDataType(),
        name: dsTCoords.getName()
      });
    }

    if (hasPtScalars) {
      var dsScalars = lds.getPointData().getScalars();
      newPtScalars = vtkDataArray.newInstance({
        numberOfComponents: dsScalars.getNumberOfComponents(),
        numberOfTuples: numPts,
        size: numPts * dsScalars.getNumberOfComponents(),
        dataType: dsScalars.getDataType(),
        name: dsScalars.getName()
      });
    }

    numPts = 0;
    numVerts = 0;
    numLines = 0;
    numStrips = 0;
    numPolys = 0;

    for (var _i = 0; _i < numberOfInputs; _i++) {
      var _ds = inData[_i];
      pointData.set(_ds.getPoints().getData(), numPts * 3);
      appendCellData(vertData, _ds.getVerts().getData(), numPts, numVerts);
      numVerts += _ds.getVerts().getNumberOfValues();
      appendCellData(lineData, _ds.getLines().getData(), numPts, numLines);
      numLines += _ds.getLines().getNumberOfValues();
      appendCellData(stripData, _ds.getStrips().getData(), numPts, numStrips);
      numStrips += _ds.getStrips().getNumberOfValues();
      appendCellData(polyData, _ds.getPolys().getData(), numPts, numPolys);
      numPolys += _ds.getPolys().getNumberOfValues();

      var dsPD = _ds.getPointData();

      if (hasPtNormals) {
        var ptNorms = dsPD.getNormals();
        newPtNormals.getData().set(ptNorms.getData(), numPts * 3);
      }

      if (hasPtTCoords) {
        var ptTCoords = dsPD.getTCoords();
        newPtTCoords.getData().set(ptTCoords.getData(), numPts * 2);
      }

      if (hasPtScalars) {
        var ptScalars = dsPD.getScalars();
        newPtScalars.getData().set(ptScalars.getData(), numPts * newPtScalars.getNumberOfComponents());
      }

      numPts += _ds.getPoints().getNumberOfPoints();
    }

    output.setPoints(points);
    output.getVerts().setData(vertData);
    output.getLines().setData(lineData);
    output.getStrips().setData(stripData);
    output.getPolys().setData(polyData);

    if (newPtNormals) {
      output.getPointData().setNormals(newPtNormals);
    }

    if (newPtTCoords) {
      output.getPointData().setTCoords(newPtTCoords);
    }

    if (newPtScalars) {
      output.getPointData().setScalars(newPtScalars);
    }

    outData[0] = output;
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  outputPointsPrecision: DesiredOutputPrecision.DEFAULT
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Build VTK API

  macro.setGet(publicAPI, model, ['outputPointsPrecision']); // Make this a VTK object

  macro.obj(publicAPI, model); // Also make it an algorithm with one input and one output

  macro.algo(publicAPI, model, 1, 1); // Object specific methods

  vtkAppendPolyData(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkAppendPolyData'); // ----------------------------------------------------------------------------

var vtkAppendPolyData$1 = {
  newInstance: newInstance,
  extend: extend
};

export { vtkAppendPolyData$1 as default, extend, newInstance };
