import _defineProperty from '@babel/runtime/helpers/defineProperty';
import { newInstance as newInstance$1 } from '../../macros.js';
import vtkWebGPUBufferManager from './BufferManager.js';
import vtkWebGPUCellArrayMapper from './CellArrayMapper.js';
import vtkViewNode from '../SceneGraph/ViewNode.js';
import { registerOverride } from './ViewNodeFactory.js';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var PrimitiveTypes = vtkWebGPUBufferManager.PrimitiveTypes; // ----------------------------------------------------------------------------
// vtkWebGPUPolyDataMapper methods
// ----------------------------------------------------------------------------

function vtkWebGPUPolyDataMapper2D(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkWebGPUPolyDataMapper2D');

  publicAPI.createCellArrayMapper = function () {
    return vtkWebGPUCellArrayMapper.newInstance();
  };

  publicAPI.buildPass = function (prepass) {
    if (prepass) {
      model.WebGPUActor = publicAPI.getFirstAncestorOfType('vtkWebGPUActor2D');

      if (!model.renderable.getStatic()) {
        model.renderable.update();
      }

      var poly = model.renderable.getInputData();
      model.renderable.mapScalars(poly, 1.0);
      publicAPI.updateCellArrayMappers(poly);
    }
  };

  publicAPI.updateCellArrayMappers = function (poly) {
    var prims = [poly.getVerts(), poly.getLines(), poly.getPolys(), poly.getStrips()]; // we instantiate a cell array mapper for each cellArray that has cells
    // and they handle the rendering of that cell array

    var cellMappers = [];
    var cellOffset = 0;

    for (var i = PrimitiveTypes.Points; i <= PrimitiveTypes.Triangles; i++) {
      if (prims[i].getNumberOfValues() > 0) {
        if (!model.primitives[i]) {
          model.primitives[i] = publicAPI.createCellArrayMapper();
        }

        var cellMapper = model.primitives[i];
        cellMapper.setCellArray(prims[i]);
        cellMapper.setCurrentInput(poly);
        cellMapper.setCellOffset(cellOffset);
        cellMapper.setPrimitiveType(i);
        cellMapper.setRenderable(model.renderable);
        cellMapper.setIs2D(true);
        cellOffset += prims[i].getNumberOfCells();
        cellMappers.push(cellMapper);
      } else {
        model.primitives[i] = null;
      }
    }

    publicAPI.prepareNodes();
    publicAPI.addMissingChildren(cellMappers);
    publicAPI.removeUnusedNodes();
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


function defaultValues(initialValues) {
  return _objectSpread({
    primitives: []
  }, initialValues);
} // ----------------------------------------------------------------------------


function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, defaultValues(initialValues)); // Inheritance

  vtkViewNode.extend(publicAPI, model, initialValues);
  model.primitives = []; // Object methods

  vtkWebGPUPolyDataMapper2D(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = newInstance$1(extend, 'vtkWebGPUPolyDataMapper2D'); // ----------------------------------------------------------------------------

var index = {
  newInstance: newInstance,
  extend: extend
}; // Register ourself to WebGPU backend if imported

registerOverride('vtkMapper2D', newInstance);

export { index as default, extend, newInstance };
