import macro from '../../macros.js';
import vtkDataArray from './DataArray.js';
import { VtkDataTypes } from './DataArray/Constants.js';

var vtkErrorMacro = macro.vtkErrorMacro;
var INVALID_BOUNDS = [1, -1, 1, -1, 1, -1]; // ----------------------------------------------------------------------------
// vtkPoints methods
// ----------------------------------------------------------------------------

function vtkPoints(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkPoints'); // Forwarding methods

  publicAPI.getNumberOfPoints = publicAPI.getNumberOfTuples;

  publicAPI.setNumberOfPoints = function (nbPoints) {
    var dimension = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;

    if (publicAPI.getNumberOfPoints() !== nbPoints) {
      model.size = nbPoints * dimension;
      model.values = macro.newTypedArray(model.dataType, model.size);
      publicAPI.setNumberOfComponents(dimension);
      publicAPI.modified();
    }
  };

  publicAPI.setPoint = function (idx) {
    for (var _len = arguments.length, xyz = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      xyz[_key - 1] = arguments[_key];
    }

    publicAPI.setTuple(idx, xyz);
  };

  publicAPI.getPoint = publicAPI.getTuple;

  publicAPI.insertNextPoint = function (x, y, z) {
    return publicAPI.insertNextTuple([x, y, z]);
  };

  publicAPI.getBounds = function () {
    if (publicAPI.getNumberOfComponents() === 3) {
      var _xRange = publicAPI.getRange(0);

      model.bounds[0] = _xRange[0];
      model.bounds[1] = _xRange[1];

      var _yRange = publicAPI.getRange(1);

      model.bounds[2] = _yRange[0];
      model.bounds[3] = _yRange[1];
      var zRange = publicAPI.getRange(2);
      model.bounds[4] = zRange[0];
      model.bounds[5] = zRange[1];
      return model.bounds;
    }

    if (publicAPI.getNumberOfComponents() !== 2) {
      vtkErrorMacro("getBounds called on an array with components of\n        ".concat(publicAPI.getNumberOfComponents()));
      return INVALID_BOUNDS;
    }

    var xRange = publicAPI.getRange(0);
    model.bounds[0] = xRange[0];
    model.bounds[1] = xRange[1];
    var yRange = publicAPI.getRange(1);
    model.bounds[2] = yRange[0];
    model.bounds[3] = yRange[1];
    model.bounds[4] = 0;
    model.bounds[5] = 0;
    return model.bounds;
  }; // Trigger the computation of bounds


  publicAPI.computeBounds = publicAPI.getBounds; // Initialize

  publicAPI.setNumberOfComponents(model.numberOfComponents < 2 ? 3 : model.numberOfComponents);
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  empty: true,
  numberOfComponents: 3,
  dataType: VtkDataTypes.FLOAT,
  bounds: [1, -1, 1, -1, 1, -1]
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues);
  vtkDataArray.extend(publicAPI, model, initialValues);
  vtkPoints(publicAPI, model);
} // ----------------------------------------------------------------------------

var newInstance = macro.newInstance(extend, 'vtkPoints'); // ----------------------------------------------------------------------------

var vtkPoints$1 = {
  newInstance: newInstance,
  extend: extend
};

export { vtkPoints$1 as default, extend, newInstance };
