var IDENTITY = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];
var IDENTITY_3X3 = [1, 0, 0, 0, 1, 0, 0, 0, 1];
var EPSILON = 1e-6;
var VTK_SMALL_NUMBER = 1.0e-12;
var Constants = {
  IDENTITY: IDENTITY,
  IDENTITY_3X3: IDENTITY_3X3,
  EPSILON: EPSILON,
  VTK_SMALL_NUMBER: VTK_SMALL_NUMBER
};

export { EPSILON, IDENTITY, IDENTITY_3X3, VTK_SMALL_NUMBER, Constants as default };
