import macro from '../../macros.js';
import vtkAbstractMapper3D from './AbstractMapper3D.js';

// vtkAbstractImageMapper methods
// ----------------------------------------------------------------------------

function vtkAbstractImageMapper(publicAPI, model) {
  model.classHierarchy.push('vtkAbstractImageMapper');

  publicAPI.getIsOpaque = function () {
    return true;
  };

  publicAPI.getCurrentImage = function () {
    return null;
  };
} // ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------


var DEFAULT_VALUES = {
  slice: 0,
  customDisplayExtent: [0, 0, 0, 0, 0, 0],
  useCustomExtents: false,
  backgroundColor: [0, 0, 0, 1]
}; // ----------------------------------------------------------------------------

function extend(publicAPI, model) {
  var initialValues = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  Object.assign(model, DEFAULT_VALUES, initialValues); // Build VTK API

  vtkAbstractMapper3D.extend(publicAPI, model, initialValues);
  macro.setGet(publicAPI, model, ['slice', 'useCustomExtents']);
  macro.setGetArray(publicAPI, model, ['customDisplayExtent'], 6);
  macro.setGetArray(publicAPI, model, ['backgroundColor'], 4);
  vtkAbstractImageMapper(publicAPI, model);
} // ----------------------------------------------------------------------------

var vtkAbstractImageMapper$1 = {
  extend: extend
};

export { vtkAbstractImageMapper$1 as default, extend };
